export default {
  "Bebês": {
    valor: 'Bebês',
  },
  "Crianças": {
    valor: 'Crianças',
  },
  "Adolecentes": {
    valor: 'Adolecentes',
  },
  "Adultos": {
    valor: 'Adultos',
  },
  "Idosos": {
    valor: 'Idosos',
  },
};
