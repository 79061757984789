export default {
  "Ativo": {
    nome: 'Ativo',
    valor: 'Active',
  },
  "Inativa": {
    nome: 'Inativo',
    valor: 'Inactive',
  },
};
